<template>
	<div class="grid-gallery">
		<!--
			CSS can create proper masonry only using `columns` property, but we can't control image order when using it. So order is
			adjusted with JS by creating two lists, one for desktop and one for mobile. As we can't define which list should be passed
			with JS (would required checking `window` size, which would mess with SSR), two lists are created and only one is displayed.
		-->
		<template v-if="isMasonryLayout">
			<GalleryList
				class="grid-gallery-masonry--desktop"
				v-bind="{
					images: masonryImages,
					...props,
				}"
				v-on="galleryListListeners"
			/>
			<GalleryList
				class="grid-gallery-masonry--mobile"
				v-bind="{
					images: masonryImagesMobile,
					...props,
				}"
				v-on="galleryListListeners"
			/>
		</template>
		<GalleryList
			v-else
			v-bind="{
				images: props.gridImages,
				...props,
			}"
			v-on="galleryListListeners"
		/>
	</div>
</template>

<script setup lang="ts">
import GalleryList from '@zyro-inc/site-modules/components/elements/gallery/GridGalleryList.vue';
import { ComposedGalleryImage } from '@zyro-inc/site-modules/types';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
	columnCount: number,
	columnGap: number,
	mobileColumnCount: number,
	mobileColumnGap: number,
	gridImages: ComposedGalleryImage[],
	isLightboxEnabled?: boolean,
	isMasonryLayout?: boolean,
	loadImages?: boolean,
}>(), {
	isLightboxEnabled: false,
	isMasonryLayout: false,
	loadImages: true,
});

const emit = defineEmits<{
	'image-click': [ComposedGalleryImage[], number],
	'image-load': [],
}>();

const galleryListListeners = {
	'image-click': (images: ComposedGalleryImage[], index: number) => emit('image-click', images, index),
	'image-load': () => emit('image-load'),
};

const getMasonryImages = (columnCount: number) => props.gridImages.reduce((images, image, index) => images.map(
	(column, imageIndex) => (imageIndex === index % columnCount ? [
		...column,
		image,
	] : column),
), Array(columnCount).fill([])).flat();

const masonryImages = computed<ComposedGalleryImage[]>(() => getMasonryImages(props.columnCount));
const masonryImagesMobile = computed<ComposedGalleryImage[]>(() => getMasonryImages(props.mobileColumnCount));
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.grid-gallery-masonry--mobile {
	display: none;
}

@include site-engine-mobile {
	.grid-gallery-masonry {
		&--desktop {
			display: none;
		}

		&--mobile {
			display: block;
		}
	}
}
</style>
