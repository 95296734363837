<template>
	<div
		:class="{
			'gallery-list-masonry': props.isMasonryLayout,
			'gallery-list-grid': !props.isMasonryLayout,
		}"
	>
		<div
			v-for="(image, index) in images"
			:key="`${index}${image.src}`"
			:class="{
				'gallery-list-masonry__image-wrapper': props.isMasonryLayout,
				'gallery-list-grid__image-wrapper': !props.isMasonryLayout,
			}"
		>
			<GridImage
				:key="`${index}${image.src}`"
				:class="{
					'gallery-list-masonry__image': props.isMasonryLayout,
					'gallery-list-grid__image': !props.isMasonryLayout,
				}"
				:alt="image.alt"
				:src="props.loadImages ? image.src : ''"
				:srcset="image.srcset"
				:sizes="image.sizes"
				:element-width="image.width"
				:element-height="image.height"
				:is-lightbox-enabled="props.isLightboxEnabled"
				:reset-mobile-position="false"
				prevent-drag
				is-eager
				:is-unstyled="props.isMasonryLayout"
				@image-click="emit('image-click', images, index)"
				@image-load="emit('image-load')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import GridImage from '@zyro-inc/site-modules/components/elements/image/GridImage.vue';
import { ComposedGalleryImage } from '@zyro-inc/site-modules/types';

const props = withDefaults(defineProps<{
	images: ComposedGalleryImage[],
	isMasonryLayout?: boolean,
	loadImages?: boolean,
	isLightboxEnabled?: boolean,
	columnCount: number,
	mobileColumnCount: number,
	columnGap: number,
	mobileColumnGap: number,
}>(), {
	loadImages: true,
});

const emit = defineEmits<{
	'image-click': [ComposedGalleryImage[], number],
	'image-load': [],
}>();

const columnGapPx = computed(() => `${props.columnGap}px`);
const mobileColumnGapPx = computed(() => `${props.mobileColumnGap}px`);
</script>

<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.grid-gallery {
	:deep() {
		img[src=""] {
			visibility: hidden;
		}
	}
}

.gallery-list-masonry {
	columns: v-bind('props.columnCount');
	gap: v-bind(columnGapPx);
	margin-bottom: calc(-1 * v-bind(columnGapPx));

	&__image-wrapper {
		display: contents;
	}

	&__image {
		padding-bottom: v-bind(columnGapPx);
	}
}

.gallery-list-grid {
	display: grid;
	grid-template-columns: repeat(v-bind('props.columnCount'), 1fr);
	grid-gap: v-bind(columnGapPx);

	&__image-wrapper {
		position: relative;

		// Used to retain identical height for all Grid images
		padding-top: 100%;

		// Increased specificity over `GridImage` styles
		.gallery-list-grid__image {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

@include site-engine-mobile {
	.gallery-list-masonry {
		columns: v-bind('props.mobileColumnCount');
		gap: v-bind(mobileColumnGapPx);
	}

	.gallery-list-grid {
		grid-template-columns: repeat(v-bind('props.mobileColumnCount'), 1fr);
		grid-gap: v-bind(mobileColumnGapPx);
	}
}
</style>
